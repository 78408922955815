
.temp_image {
  background-color: rgba(205, 211, 255, 0.8);
  width: 100%;
  height: 500px;
}

h2 {
  font-style: italic;
}

* {
  box-sizing: border-box;
}