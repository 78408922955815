.hero-servizi {
    width: 100%;
    height: 40vh;
    overflow: hidden;
    display: flex;
    position: relative;
    margin-top: 100px;
    background: rgb(0,0,0);
    background: linear-gradient(41deg, rgba(0,0,0,1) 0%, rgba(100,0,0,1) 69%, rgba(187,0,0,1) 100%);
}

p {
    font-size: 16px;
}

.servizi--catalogo {
    padding: 0 20px;
    margin: 40px 0px;
}

.catalogo--categoria {
    position: absolute;
    align-self: center;
    left: 50%; /* sposta il div a metà della larghezza del contenitore */
    transform: translateX(-50%); /* sposta indietro di metà della larghezza del div */
    margin-bottom: 20px;
    z-index: 2;
    position: absolute;
    p {
        color: white;
        font-style: italic;
        text-align: center;
    }
}

.catalogo--categoria--h2 {
    margin-top: 0px;
    font-size: 60px;
    font-style: normal;
    color: white;
}

.catalogo--categoria--p {
    font-size: 20px;
}

.accordion-button:focus {
    font-size: 20px !important;
    background-color: rgb(117, 117, 117) !important;
    color: white !important;
    font-weight: 600;
    border: none;
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.accordion-body {
    font-size: 18px !important;
    p {
        font-size: 18px !important;
    }
}

@media (max-width: 768px) {
    .hero-servizi {
        margin-top: 70px;
    }

    .catalogo--categoria {
        left: 0;
        width: 100%;
        transform: translateX(0);
        text-align: center;
    }

    .catalogo--categoria--h2 {
        font-size: 42px;
        width: 100%;
        text-align: center;
    }
}